<template>
  <div class="survey_slider">
    <v-slider :value="value"
              @change="onUpdateValue"
              :min="options.min"
              :max="options.max"
              :step="options.step"
              :disabled="isReadSurvey || options.readonly || nc"
              :tick-labels="labels"
              ticks="always"
              tick-size="4"
              thumb-label="always"
              :thumb-size="20"
    />
    <div class="switches">
      <v-switch :label="$t('survey.slider.nc')"
                v-if="!options.mandatory"
                v-model="nc"
                :disabled="isReadSurvey || na"
                class="switch"
      />
      <v-switch :label="$t('survey.slider.na')"
                v-if="!options.mandatory"
                v-model="na"
                :disabled="isReadSurvey || nc"
                class="switch"
      />
    </div>
  </div>
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'
import ncMixin from '@/components/Survey/Reader/ncMixin'
import naMixin from '@/components/Survey/Reader/naMixin'

export default {
  name: 'SurveyQuestionScale',

  mixins: [questionMixins, ncMixin, naMixin],

  computed: {
    labels () {
      let labelArray = []

      if (!isNaN(parseInt(this.options.min)) && !isNaN(parseInt(this.options.max)) && !isNaN(parseInt(this.options.step))) {
        for (let i = parseInt(this.options.min); i <= parseInt(this.options.max); i = i + parseInt(this.options.step)) {
          labelArray.push(String(i))
        }
      }

      return labelArray
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.survey_slider {
  display: flex;
  flex-direction: column;

  @media all and (min-width: $s) {
    flex-direction: row;
  }

  .v-input::v-deep {
    margin-top: 30px;

    @media all and (min-width: $s) {
      margin-right: 30px;
    }

    .v-slider__thumb-label {
      span {
        color: white;
        font-size: 20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "src/assets/style/style";

.switches > * {
  display: inline-flex;
  margin-right: 20px;
}

</style>